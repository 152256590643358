<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title"><i class="far fa-history text-primary"></i>{{$t('history.1')}}</div>
		</div>
		<!--sub_top end-->
		<div class="section_right_inner">
			<!--section_right_inner-->
			<div class="notice_table04">
				<table style="width:100%" class="main_table02">
					<thead>
						<tr>
							<th>{{$t('history.13')}}</th>
							<th>{{$t('history.2')}}</th>
							<th>{{$t('history.3')}}</th>
							<th>{{$t('history.6')}}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in bonusHistory" :key="index">
							<td>{{item.date}}</td>
							<td>{{numberWithCommas(item.price)}}</td>
							<td>{{CheckType(item.type)}}</td>
							<td>{{item.target}}</td>
						</tr>
					</tbody>
					<tbody></tbody>
				</table>
			</div>
		</div>
		<!--section_right_inner end-->
	</div>
	<!--section_right end-->
</template>
<script>
const CryptoJS = require("crypto-js");
export default {
	data(){
		return{
			bonusHistory: [],
		}
	},
	mounted () {
		this.GetExtraList()
	},
	methods : {
		CheckType(type){
			let type_arr = type.split('_');

			if(type_arr[0] =='R'){
				return this.$t('assets.7')
			}else if(type_arr[0] =='SM'){
				if(type_arr[1] =='1'){
					return this.$t('assets.8') + "_1"
				}else if(type_arr[1] =='2'){
					return this.$t('assets.8') + "_2"
				}else if(type_arr[1] =='3'){
					return this.$t('assets.8') + "_3"
				}
			}else if(type_arr[0] =='S'){
				return this.$t('assets.9')
			}else if(type_arr[0] =='G'){
				if(type_arr[1] =='1'){
					return this.$t('assets.10') + "_1"
				}else if(type_arr[1] =='2'){
					return this.$t('assets.10') + "_2"
				}else if(type_arr[1] =='3'){
					return this.$t('assets.10') + "_3"
				}else if(type_arr[1] =='4'){
					return this.$t('assets.10') + "_4"
				}else if(type_arr[1] =='5'){
					return this.$t('assets.10') + "_5"
				}else if(type_arr[1] =='6'){
					return this.$t('assets.10') + "_6"
				}else if(type_arr[1] =='7'){
					return this.$t('assets.10') + "_7"
				}
			}else if(type_arr[0] =='C'){
				return this.$t('assets.11')
			}else if(type_arr[0] =='JO'){
				return this.$t('assets.23')
			}else if(type_arr[0] =='J'){
				return this.$t('assets.22')
			}
		},
		GetExtraList(){
			const body = { };
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

			this.$http.post(`/member/extra/GetExtraList`,{req}).then(
				res => {
					if(res.status==200){
						if(res.data.code=='200'){
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)
							this.bonusHistory = body.list
						}else if(res.data.code=='9999'){
							this.$store.dispatch('SETLOGOUT').then(
								()=>{
									window.location.href= `/${this.$i18n.locale}/login`;
								}
							)
						}
					}

				}
			).catch(() => {});
		},
		numberWithCommas(x) {
			var parts=x.toString().split(".");
			return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
		},
	}
}
</script>
<style>
</style>